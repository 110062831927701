import { FunctionComponent, useEffect, useState } from 'react';
import styles from './index.module.css';
import Error404 from 'pages/Error404/Error404';
import { PageLoading } from 'components/shared/PageLoading';
import { IPageLogin } from 'types/generated/strapi';
import SeoHead from 'components/shared/SeoHead';
import * as strapi from '@casbah/strapi-fetch';
import { getStrapiURL } from '../../utils/urls';

const Login: FunctionComponent = () => {
  const [iframeIsLoading, setIframeIsLoading] = useState(true);
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageData, setPageData] = useState<IPageLogin>();

  useEffect(() => {
    (async () => {
      const resp = await strapi.get<IPageLogin>(getStrapiURL('/page-login'));
      if (resp.ok) setPageData(resp.payload);
      else console.error(resp.error);

      setPageIsLoading(false);
      document.dispatchEvent(new Event('page-fetched'));
    })();
  }, []);

  const { seo } = {
    ...pageData,
  };

  if (!pageIsLoading && !pageData) return <Error404 />;

  const onIframeLoad = () => {
    window.scrollTo(0, 0);
    setIframeIsLoading(false);
  };

  return (
    <>
      {pageIsLoading ? (
        <PageLoading />
      ) : (
        <>
          {seo && <SeoHead seo={seo} />}
          {iframeIsLoading && (
            <div className={styles.loaderContainer}>
              <div className={styles.loader}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          )}

          <iframe
            onLoad={onIframeLoad}
            className={styles.creatorLoginPageIframe}
            src="https://influencer.communityxseen.com/login"
            title="Sign Up"
            style={{
              height: '100vh',
              display: iframeIsLoading ? 'none' : 'block',
            }}
          />
        </>
      )}
    </>
  );
};

export default Login;
