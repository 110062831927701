import StrapiImage from 'components/shared/StrapiImage';
import { FunctionComponent } from 'react';
import { IIconsAndText } from 'types/generated/strapi';
import styles from './index.module.css';

interface IconsAndTextProps {
  data: IIconsAndText;
}
const IconsAndText: FunctionComponent<IconsAndTextProps> = (props) => {
  const { items } = props.data;

  return (
    <div className={styles.twoColumnsIconAndText}>
      <div className={styles.twoColumnsIconAndTextInner}>
        <div className={styles.columns}>
          {items.length &&
            items.map(({ id, text, title, icon }) => (
              <div key={id} className={styles.column}>
                <div className={styles.columnInner}>
                  <div className={styles.iconWrapper}>
                    <StrapiImage
                      className={styles.icon}
                      image={icon}
                      format="small"
                    />
                  </div>
                  <div className={styles.title}>{title}</div>
                  <div className={styles.text}>{text}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default IconsAndText;
