import { FunctionComponent, useEffect } from 'react';
import styles from './index.module.css';
import MainMenu from 'components/shared/MainMenu';
import Footer from 'components/shared/Footer';

const TermsOfUsePage: FunctionComponent = () => {
  useEffect(() => {
    document.dispatchEvent(new Event('page-fetched'));
  }, []);

  return (
    <>
      <MainMenu />
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <div className={styles.buttons}>
            <a
              className={styles.button}
              href="/legal/terms-of-use-uk-ireland-canada"
              target="_blank"
            >
              Click here for UK, Ireland & Canada Terms of Use
            </a>
            <a
              className={styles.button}
              href="/legal/terms-of-use-us"
              target="_blank"
            >
              Click here for USA Terms of Use
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUsePage;
