import StrapiImage from 'components/shared/StrapiImage';
import StrapiLink from 'components/shared/StrapiLink';
import { FunctionComponent, useEffect, useRef } from 'react';
import { IFile, IPhotoStackAndText } from 'types/generated/strapi';
import ReactMarkdown from 'react-markdown';
import styles from './index.module.css';
import { sliderController } from 'utils/sliderController';
import anime from 'animejs';
import { ReactComponent as SliderArrow } from 'assets/svg/slider-arrow.svg';

interface PhotoStackAndTextProps {
  data: IPhotoStackAndText;
}

const PhotoStackAndText: FunctionComponent<PhotoStackAndTextProps> = (
  props
) => {
  const { text, photos, cta, ctaTitle } = props.data;

  return (
    <div className={styles.photoStackAndText}>
      <div className={styles.photoStackAndTextInner}>
        <div className={styles.photos}>
          <Slider photos={photos} />
        </div>
        <div className={styles.content}>
          <ReactMarkdown
            children={text}
            className={styles.text}
            linkTarget="_blank"
            components={{
              strong: ({ node, ...props }) => (
                <span className={styles.textTitle} {...props} />
              ),
            }}
          />
          <div className={styles.cta}>
            <div className={styles.ctaTitle}>{ctaTitle}</div>
            <StrapiLink className={styles.ctaLink} link={cta} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Slider = (props: { photos: IFile[] }) => {
  const { photos } = props;
  const slidesWrapperRef = useRef<HTMLDivElement>(null);
  const sliderControllerRef = useRef<ReturnType<typeof sliderController>>();

  const onPrevSlideClick = () => {
    if (!sliderControllerRef.current) return;
    const { currentSlideIndex } = sliderControllerRef.current.state;
    if (currentSlideIndex === 0) return;
    sliderControllerRef.current?.actions.playPrev();
  };

  const onNextSlideClick = () => {
    if (!sliderControllerRef.current) return;
    const { currentSlideIndex, slidesLength } =
      sliderControllerRef.current.state;
    if (currentSlideIndex === slidesLength - 1) return;
    sliderControllerRef.current?.actions.playNext();
  };

  useEffect(() => {
    function initSliderStyles() {
      if (!slidesWrapperRef.current) return;

      anime.set(slidesWrapperRef.current.children, {
        translateZ: (a: HTMLElement, b: number, c: number) => `${b * -10}px`,
        translateX: (a: HTMLElement, b: number, c: number) => `${b * 40}px`,
      });
    }

    initSliderStyles();

    sliderControllerRef.current = sliderController({
      slidesLength: photos.length,
      onSlide: (currentSlideIndex, nextSlideIndex, done) => {
        if (!slidesWrapperRef.current) return;
        const direction =
          nextSlideIndex > currentSlideIndex ? 'forward' : 'backward';

        if (direction === 'forward') {
          anime({
            targets: slidesWrapperRef.current.children,
            translateZ: '+=10',
            translateX: '-=40',
            opacity: (c: any, i: number) => (i <= currentSlideIndex ? 0 : 1),
            duration: 500,
            easing: 'easeInOutQuad',
            complete: () => done(),
          });
        } else {
          anime({
            targets: slidesWrapperRef.current.children,
            translateZ: '-=10',
            translateX: '+=40',
            opacity: (c: any, i: number) => (i >= nextSlideIndex ? 1 : 0),
            duration: 500,
            easing: 'easeInOutQuad',
            complete: () => done(),
          });
        }
      },
    });

    return () => {
      sliderControllerRef.current?.actions.destroy();
    };
  }, [photos.length]);

  return (
    <>
      <div ref={slidesWrapperRef} className={styles.slides}>
        {photos.map((photo) => (
          <StrapiImage key={photo.id} image={photo} format="medium" />
        ))}
      </div>
      {photos.length > 1 && (
        <div className={styles.sliderButtons}>
          <button
            className={styles.sliderPrevButton}
            onClick={onPrevSlideClick}
          >
            <SliderArrow className={styles.sliderArrowPrev} />
          </button>
          <button
            className={styles.sliderNextButton}
            onClick={onNextSlideClick}
          >
            <SliderArrow className={styles.sliderArrowNext} />
          </button>
        </div>
      )}
    </>
  );
};

export default PhotoStackAndText;
