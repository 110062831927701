import { FunctionComponent, useEffect, useState } from 'react';
import styles from './index.module.css';
import Error404 from 'pages/Error404/Error404';
import { PageLoading } from 'components/shared/PageLoading';
import { IPageSignUp } from 'types/generated/strapi';
import SeoHead from 'components/shared/SeoHead';
import * as strapi from '@casbah/strapi-fetch';
import { getStrapiURL } from '../../utils/urls';
import SignedUp from 'assets/img/SignupComplete.png';

const SignUpComplete: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageData, setPageData] = useState<IPageSignUp>();

  useEffect(() => {
    (async () => {
      const resp = await strapi.get<IPageSignUp>(getStrapiURL('/page-sign-up'));
      if (resp.ok) setPageData(resp.payload);
      else console.error(resp.error);

      setPageIsLoading(false);
      document.dispatchEvent(new Event('page-fetched'));
    })();
  }, []);

  const { seo } = {...pageData};

  if (!pageIsLoading && !pageData) return <Error404 />;


  return (
    <>
      {pageIsLoading ? (
        <PageLoading />
      ) : (
        <>
          {seo && <SeoHead seo={seo} />}
          <div className={styles.signedUpContainer}>
            <img
              src={SignedUp}
              alt="Thank you for signing up"
              className={styles.signedUpMessage}/>
          </div>
        </>
      )}
    </>
  );
};

export default SignUpComplete;
