import { FunctionComponent, useEffect, useState } from 'react';
import Error404 from 'pages/Error404/Error404';
import { PageLoading } from 'components/shared/PageLoading';
import {IPageCreators} from 'types/generated/strapi';
import TitleCenter from 'components/sections/TitleCenter';
import MainMenu from 'components/shared/MainMenu';
import Footer from 'components/shared/Footer';
import SeoHead from 'components/shared/SeoHead';
import CreatorsAnimation from 'components/sections/CreatorsAnimation';
import VideoAndFeatures from 'components/sections/VideoAndFeatures';
import TextBlock from 'components/sections/TextBlock';
import Hero from 'components/sections/Hero';
import Ticker from 'components/sections/Ticker';
import TripleVideoAndText from 'components/sections/TripleVideoAndText';
import PhotoStackAndText from 'components/sections/PhotoStackAndText';
import CtaSection from 'components/sections/CtaSection';
import * as strapi from '@casbah/strapi-fetch'
import {getStrapiURL} from "../../utils/urls";


const CreatorsPage: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageData, setPageData] = useState<IPageCreators>();

  useEffect(() => {
    (async () => {
      const resp = await strapi.get<IPageCreators>(
        getStrapiURL('/page-creators')
      )
      if(resp.ok) setPageData(resp.payload);
      else console.error(resp.error)

      setPageIsLoading(false);
      document.dispatchEvent(new Event('page-fetched'));
    })();
  }, []);

  const {
    hero,
    textBlock,
    ticker,
    videoAndFeatures,
    creatorsAnimation,
    titleCenter,
    tripleVideoAndText,
    ticker2,
    photoStackAndText,
    seo,
    signUp
  } = {
    ...pageData,
  };

  if (!pageIsLoading && !pageData) return <Error404 />;

  return (
    <>
      <MainMenu />
      {pageIsLoading ? (
        <PageLoading />
      ) : (
        <>
          {seo && <SeoHead seo={seo} />}
          {hero && <Hero data={hero} />}
          {textBlock && <TextBlock data={textBlock} />}
          {ticker && <Ticker data={ticker} />}
          {creatorsAnimation && <CreatorsAnimation data={creatorsAnimation} />}
          {videoAndFeatures && <VideoAndFeatures data={videoAndFeatures} />}
          {titleCenter && <TitleCenter data={titleCenter} />}
          {tripleVideoAndText && (
            <TripleVideoAndText data={tripleVideoAndText} />
          )}
          {ticker2 && <Ticker data={ticker2} />}
          {photoStackAndText && <PhotoStackAndText data={photoStackAndText} />}
          {signUp && <CtaSection data={signUp} />}
        </>
      )}
      <Footer />
    </>
  );
};

export default CreatorsPage;
