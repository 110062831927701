import clsx from 'clsx';
import { CSSProperties, FunctionComponent, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
import { ITicker } from 'types/generated/strapi';
import styles from './index.module.css';

interface TickerProps {
  data: ITicker;
}
const Ticker: FunctionComponent<TickerProps> = (props) => {
  const { video, text, textColor, textBackgroundColor } = props.data;
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const duplicatedText = useMemo(() => {
    return `${text}&nbsp;${text}&nbsp;${text}&nbsp;${text}&nbsp;`;
  }, [text]);

  if (video)
    return (
      <video
        className={styles.video}
        src={video.url}
        muted
        playsInline
        autoPlay
        loop
      />
    );

  return (
    <div
      className={clsx(
        styles.tickerWrapper,
        isHomePage && styles.tickerWrapperHomePage
      )}
      style={
        {
          '--color': textColor,
          '--background-color': textBackgroundColor,
        } as CSSProperties
      }
    >
      {text && (
        <ReactMarkdown
          children={duplicatedText}
          className={clsx('ticker', styles.ticker)}
          components={{
            strong: ({ node, ...props }) => (
              <span className={styles.textHighlight} {...props} />
            ),
          }}
        />
      )}
    </div>
  );
};

export default Ticker;
