import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import 'App.css';
import Error404 from 'pages/Error404/Error404';
import HomePage from 'pages/Home';
import BrandsPage from 'pages/Brands';
import ServicesPage from 'pages/Services';
import CreatorsPage from 'pages/Creators';
import FaqPage from 'pages/Faq';
import CaseStudyPage from 'pages/CaseStudy';
import SignUp from 'pages/SignUp';
import LoginPage from 'pages/Login';
import SignUpComplete from 'pages/SignUpComplete';
import TermsOfUsePage from 'pages/Legal/TermsOfUse';
import TermsOfUsePageUSA from 'pages/Legal/TermsOfUseUS';
import TermsOfUsePageUK from 'pages/Legal/TermsOfUseUK';
import SustainabilityPolicyPage from 'pages/Legal/SustainabilityPolicy';
import ScrollToTop from 'components/shared/ScrollToTop';
import CookieNotification from 'components/shared/CookieNotification';
import initGtm from 'utils/gtm';
import { useAppDispatch } from 'redux/hooks';
import { fetchFooter, fetchMainMenu } from 'redux/layoutSlice';
import store from './redux/store';
import { Provider } from 'react-redux';
import AntiSlaveryStatement from 'pages/Legal/AntiSlaveryStatement';
import PrivacyNotice from 'pages/Legal/PrivacyNotice';
import CaseStudiesPage from 'pages/CaseStudies';
import PrivacyNoticeUK from 'pages/Legal/PrivacyNoticeUK';
import PrivacyNoticeUSA from 'pages/Legal/PrivacyNoticeUS';
import SustainabilityReportPage from 'pages/Legal/SustainabilityReport';
import CXSEngagementLetterUK from 'pages/Legal/CXSEngagementLetterUK';
import CXSEngagementLetterUS from 'pages/Legal/CXSEngagementLetterUS';

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    initGtm();
    dispatch(fetchMainMenu());
    dispatch(fetchFooter());
  }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/brands" element={<BrandsPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/creators" element={<CreatorsPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/case-studies" element={<CaseStudiesPage />} />
        <Route path="/case-studies/:slug" element={<CaseStudyPage />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up-complete" element={<SignUpComplete />} />
        <Route path="/legal/terms-of-use" element={<TermsOfUsePage />} />
        <Route path="/legal/terms-of-use-us" element={<TermsOfUsePageUSA />} />
        <Route
          path="/legal/terms-of-use-uk-ireland-canada"
          element={<TermsOfUsePageUK />}
        />
        <Route
          path="/legal/terms-of-use-uk"
          element={
            <Navigate
              to="/legal/terms-of-use-uk-ireland-canada"
              replace={true}
            />
          }
        />
        <Route
          path="/legal/sustainability-policy"
          element={<SustainabilityPolicyPage />}
        />
        <Route
          path="/legal/sustainability-report"
          element={<SustainabilityReportPage />}
        />
        <Route
          path="/legal/engagement-terms-uk"
          element={<CXSEngagementLetterUK />}
        />
        <Route
          path="/legal/engagement-terms-us"
          element={<CXSEngagementLetterUS />}
        />
        <Route
          path="/legal/anti-slavery-statement"
          element={<AntiSlaveryStatement />}
        />
        <Route path="/legal/privacy-notice" element={<PrivacyNotice />} />
        <Route
          path="/legal/privacy-notice-uk-ireland-canada"
          element={<PrivacyNoticeUK />}
        />
        <Route
          path="/legal/privacy-notice-uk"
          element={
            <Navigate
              to="/legal/privacy-notice-uk-ireland-canada"
              replace={true}
            />
          }
        />
        <Route path="/legal/privacy-notice-us" element={<PrivacyNoticeUSA />} />
        <Route path="*" element={<Error404 />} />
      </Routes>

      <CookieNotification />
    </Router>
  );
}

const AppWithRedux = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default AppWithRedux;
