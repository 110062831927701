import { CSSProperties, FunctionComponent } from 'react';

import { ITitleCenter } from 'types/generated/strapi';

import styles from './index.module.css';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import replaceCommunitySeenFont from 'utils/replaceCommunitySeenFont';

interface TitleCenterProps {
  data: ITitleCenter;
}

const TitleCenter: FunctionComponent<TitleCenterProps> = (props) => {
  const { text, backgroundColor, textColor } = props.data;
  const location = useLocation();
  const isCreatorsPage = location.pathname.includes('/creators');

  return (
    <div
      className={clsx(styles.titleCenter, {
        [styles.titleCenterWithBackground]: backgroundColor,
        [styles.titleCenterCreatorsPage]: isCreatorsPage,
      })}
      style={
        {
          '--text-color': textColor,
          '--background-color': backgroundColor,
        } as CSSProperties
      }
    >
      <div className={styles.titleCenterInner}>
        {text && (
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            children={replaceCommunitySeenFont(text)}
            className={styles.text}
            components={{
              strong: ({ node, ...props }) => (
                <span className={styles.textTitle} {...props} />
              ),
              h2: ({ node, ...props }) => (
                <h2 className={styles.textH2} {...props}>
                  {props.children}
                </h2>
              ),
              h3: ({ node, ...props }) => (
                <h3 className={styles.textH3} {...props}>
                  {props.children}
                </h3>
              ),
              em: ({ node, ...props }) => (
                <span className={styles.textHighlight} {...props} />
              ),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TitleCenter;
