import clsx from 'clsx';
import { FunctionComponent, useState } from 'react';

import { IAccordion, IAccordionItem } from 'types/generated/strapi';

import styles from './index.module.css';

interface AccordionProps {
  data: IAccordion;
}

const Accordion: FunctionComponent<AccordionProps> = (props) => {
  const { items } = props.data;
  const [activeItem, setActiveItem] = useState<IAccordionItem>();

  const onFaqClick = (item: IAccordionItem) => {
    setActiveItem(item === activeItem ? undefined : item);
  };

  return (
    <div className={styles.accordion}>
      <div className={styles.accordionInner}>
        <ul className={styles.accordionContainer}>
          {items.map((item) => {
            const { title, text, id } = item;
            const isActive = id === activeItem?.id;

            return (
              <li
                key={id}
                onClick={() => onFaqClick(item)}
                className={clsx(
                  styles.accordionItem,
                  isActive && styles.accordionItemActive
                )}
              >
                <div className={styles.accordionItemHeader}>
                  <div className={styles.accordionItemIconContainer}>
                    <div className={clsx(styles.accordionItemIconVertLine)} />
                    <div
                      className={clsx(
                        styles.accordionItemIconHorLine,
                        isActive && styles.plus,
                        !isActive && styles.minus
                      )}
                    />
                  </div>
                  <div className={styles.accordionItemTitle}>{title}</div>
                </div>
                <div
                  className={clsx(
                    styles.accordionItemContent,
                    isActive && styles.accordionItemContentActive
                  )}
                >
                  <div className={styles.accordionItemText}>{text}</div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Accordion;
