import { FunctionComponent, useEffect, useState } from 'react';
import Error404 from 'pages/Error404/Error404';
import { PageLoading } from 'components/shared/PageLoading';
import {IPageBrands} from 'types/generated/strapi';
import MainMenu from 'components/shared/MainMenu';
import Footer from 'components/shared/Footer';
import SeoHead from 'components/shared/SeoHead';
import TextBlock from 'components/sections/TextBlock';
import Hero from 'components/sections/Hero';
import VideoGridAndText from 'components/sections/VideoGridAndText';
import Ticker from 'components/sections/Ticker';
import MediaLeftTextRight from 'components/sections/MediaLeftTextRight';
import MediaRightTextCtaLeft from 'components/sections/MediaRightTextCtaLeft';
import * as strapi from '@casbah/strapi-fetch'
import {getStrapiURL} from "../../utils/urls";

const BrandsPage: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageData, setPageData] = useState<IPageBrands>();

  useEffect(() => {
    (async () => {
      const resp = await strapi.get<IPageBrands>(getStrapiURL('/page-brands'))
      if(resp.ok) setPageData(resp.payload);
      else console.error(resp.error)

      setPageIsLoading(false);
      document.dispatchEvent(new Event('page-fetched'));
    })();
  }, []);

  const {
    hero,
    textBlock,
    videoGridAndText,
    ticker,
    mediaLeftTextRight,
    mediaRightTextCtaLeft,
    mediaRightTextCtaLeft2,
    mediaLeftTextRight2,
    seo,
  } = {
    ...pageData,
  };

  if (!pageIsLoading && !pageData) return <Error404 />;

  return (
    <>
      <MainMenu />
      {pageIsLoading ? (
        <PageLoading />
      ) : (
        <>
          {seo && <SeoHead seo={seo} />}
          {hero && <Hero data={hero} />}
          {textBlock && <TextBlock data={textBlock} />}
          {videoGridAndText && <VideoGridAndText data={videoGridAndText} />}
          {mediaLeftTextRight && (
            <MediaLeftTextRight data={mediaLeftTextRight} />
          )}
          {ticker && <Ticker data={ticker} />}
          {mediaRightTextCtaLeft && (
            <MediaRightTextCtaLeft data={mediaRightTextCtaLeft} />
          )}
          {mediaRightTextCtaLeft2 && (
            <MediaRightTextCtaLeft data={mediaRightTextCtaLeft2} />
          )}
          {mediaLeftTextRight2 && (
            <MediaLeftTextRight data={mediaLeftTextRight2} />
          )}
        </>
      )}
      <Footer />
    </>
  );
};

export default BrandsPage;
