import StrapiImage from 'components/shared/StrapiImage';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';
import { ICaseStudy } from 'types/generated/strapi';
import styles from './index.module.css';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

const CaseStudiesItem = ({
  data,
  active,
}: {
  data: ICaseStudy;
  active?: boolean;
}) => {
  const { thumbnail, slug, name, overview } = data;
  return (
    <div
      className={clsx(
        styles.caseStudyItem,
        active && styles.caseStudyItemActive
      )}
    >
      <StrapiImage
        className={styles.caseStudyItemImage}
        image={thumbnail}
        format="large"
      />
      <Link to={`/case-studies/${slug}`} className={styles.caseStudyItemText}>
        <div className={styles.caseStudyItemPreTitle}>{name}</div>
        <div className={styles.caseStudyItemTitle}>
          {overview || 'Go to case study'}
        </div>
        <div className={styles.arrowIconWrapper}>
          <ArrowIcon className={styles.arrowIcon} />
        </div>
      </Link>
    </div>
  );
};

export default CaseStudiesItem;
