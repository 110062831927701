import StrapiImage from 'components/shared/StrapiImage';
import StrapiLink from 'components/shared/StrapiLink';
import { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import { IVideoAndFeatures } from 'types/generated/strapi';

import styles from './index.module.css';

interface VideoAndFeaturesProps {
  data: IVideoAndFeatures;
}

const VideoAndFeatures: FunctionComponent<VideoAndFeaturesProps> = (props) => {
  const { video, title, items, cta } = props.data;

  return (
    <div className={styles.videoAndFeatures}>
      <video
        className={styles.video}
        src={video.url}
        loop
        playsInline
        autoPlay
        muted
      />
      <div className={styles.videoAndFeaturesInner}>
        <div className={styles.columns}>
          <ReactMarkdown
            children={title}
            className={styles.title}
            components={{
              strong: ({ node, ...props }) => (
                <span className={styles.textTitle} {...props} />
              ),
            }}
          />
          <div className={styles.items}>
            {items.map(({ text, icon }, index) => (
              <div className={styles.itemWrapper}>
                <div className={styles.item} key={index}>
                  <div className={styles.iconWrapper}>
                    <StrapiImage
                      className={styles.icon}
                      image={icon}
                      format="small"
                    />
                  </div>
                  <div className={styles.text}>{text}</div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.ctaWrapper}>
            <StrapiLink className={styles.cta} link={cta} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoAndFeatures;
