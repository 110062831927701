import { FunctionComponent, useEffect, useState } from 'react';
import Error404 from 'pages/Error404/Error404';
import { PageLoading } from 'components/shared/PageLoading';
import { IPageHome } from 'types/generated/strapi';
import Hero from 'components/sections/Hero';
import TitleCenter from 'components/sections/TitleCenter';
import MainMenu from 'components/shared/MainMenu';
import Footer from 'components/shared/Footer';
import Quotes from 'components/sections/Quotes';
import SeoHead from 'components/shared/SeoHead';
import TextBlock from 'components/sections/TextBlock';
import Grid from 'components/sections/Grid';
import CaseStudiesCarousel from 'components/sections/CaseStudiesCarousel';
import Ticker from 'components/sections/Ticker';
import * as strapi from '@casbah/strapi-fetch'
import {getStrapiURL} from "../../utils/urls";

const HomePage: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageData, setPageData] = useState<IPageHome>();

  useEffect(() => {
    (async () => {
      const resp = await strapi.get<IPageHome>(getStrapiURL('/page-home'))
      if(resp.ok) setPageData(resp.payload);
      else console.error(resp.error)

      setPageIsLoading(false);
      document.dispatchEvent(new Event('page-fetched'));
    })();
  }, []);

  const {
    hero,
    textBlock,
    titleCenter,
    quotes,
    ticker,
    quotes2,
    titleCenter2,
    grid,
    ticker2,
    caseStudiesCarousel,
    hero2,
    seo,
  } = {
    ...pageData,
  };

  if (!pageIsLoading && !pageData) return <Error404 />;

  return (
    <>
      <MainMenu />
      {pageIsLoading ? (
        <PageLoading />
      ) : (
        <>
          {seo && <SeoHead seo={seo} />}
          {hero && <Hero data={hero} />}
          {textBlock && <TextBlock data={textBlock} />}
          {titleCenter && <TitleCenter data={titleCenter} />}
          {quotes && <Quotes data={quotes} />}
          {ticker && <Ticker data={ticker} />}
          {quotes2 && <Quotes data={quotes2} />}
          {titleCenter2 && <TitleCenter data={titleCenter2} />}
          {grid && <Grid data={grid} />}
          {ticker2 && <Ticker data={ticker2} />}
          {caseStudiesCarousel && (
            <CaseStudiesCarousel data={caseStudiesCarousel} />
          )}
          {hero2 && <Hero data={hero2} />}
        </>
      )}
      <Footer />
    </>
  );
};

export default HomePage;
