import { FunctionComponent, useRef } from 'react';
import { Link } from 'react-router-dom';
import { IGrid, IGridItem } from 'types/generated/strapi';
import styles from './index.module.css';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';
interface GridProps {
  data: IGrid;
}
const Grid: FunctionComponent<GridProps> = (props) => {
  const { title, subtitle, items } = props.data;

  return (
    <div className={styles.grid}>
      <div className={styles.gridInner}>
        <div className={styles.subtitle}>{subtitle}</div>
        <div className={styles.title}>{title}</div>
        {items.length > 0 && (
          <div className={styles.gridItems}>
            {items.map((item) => (
              <GridItem key={item.id} data={item} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const GridItem = ({ data }: { data: IGridItem }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { title, subtitle, video, linkUrl } = data;

  const onMouseEnter = () => {
    videoRef.current?.play();
  };

  const onMouseLeave = () => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = 0;
    videoRef.current.pause();
  };

  return (
    <Link
      className={styles.gridItem}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      to={linkUrl}
    >
      <video
        className={styles.gridItemVideo}
        ref={videoRef}
        // Media fragment used to fix iphone not showing first frame
        // https://caniuse.com/?search=media%20fragments
        src={`${video.url}#t=0.1`}
        muted
        loop
        playsInline
      />
      <div className={styles.gridItemInner}>
        <div className={styles.gridItemTitle}>
          {title} <ArrowIcon className={styles.arrowIcon} />
        </div>
        <div className={styles.gridItemSubtitle}>{subtitle}</div>
      </div>
    </Link>
  );
};

export default Grid;
