// Slider domless controller
export function sliderController({
  slidesLength,
  autoplay = false,
  timeout = 3000,
  onSlide,
}: {
  slidesLength: number;
  autoplay?: boolean;
  timeout?: number;
  onSlide: (
    currentSlideIndex: number,
    nextSlideIndex: number,
    done: Function
  ) => void;
}) {
  const state: {
    slidesLength: number;
    currentSlideIndex: number;
    nextSlideIndex: number | null;
    playing: boolean;
    autoplay: boolean;
    timeout: number;
  } = {
    slidesLength,
    currentSlideIndex: 0,
    nextSlideIndex: null,
    playing: false,
    autoplay,
    timeout,
  };

  const helpers = {
    isPlaying() {
      return state.playing;
    },
    timer(callback: Function) {
      let lastTime = 0;
      //@ts-ignore
      const animate = (time) => {
        //@ts-ignore
        if (!this.startTime) {
          //@ts-ignore
          this.startTime = time;
        }

        //@ts-ignore
        if (time - this.startTime - lastTime > timeout) {
          callback();
          lastTime = time;
        }
        //@ts-ignore
        this.frame = requestAnimationFrame(animate);
      };
      //@ts-ignore
      animate();
    },
    cancelTimer() {
      //@ts-ignore
      this.startTime = undefined;
      //@ts-ignore
      cancelAnimationFrame(this.frame);
    },
  };

  const _actions = {
    play(index: number) {
      //If playing return or same slider
      if (helpers.isPlaying() || index === state.currentSlideIndex) return;

      state.playing = true;

      //Next Slide
      state.nextSlideIndex = slidesLength - 1 < index ? 0 : index;

      const { currentSlideIndex, nextSlideIndex } = state;

      //Current slide, next slide, callback when animation is done
      onSlide(currentSlideIndex, nextSlideIndex, _actions.stop);
    },
    stopAutoplay() {
      state.autoplay = false;
      helpers.cancelTimer();
    },
    stop() {
      state.currentSlideIndex = state.nextSlideIndex || 0;
      state.playing = false;
    },
    startAutoplay() {
      state.autoplay = true;
      function callback() {
        const index = state.currentSlideIndex + 1;
        _actions.play(index);
      }
      helpers.timer(callback);
    },
  };

  const actions = {
    playNext() {
      actions.stopAutoplay();
      const index = state.currentSlideIndex + 1;
      _actions.play(index);
    },
    playPrev() {
      actions.stopAutoplay();
      const index = state.currentSlideIndex - 1;
      _actions.play(index);
    },
    playIndex(index: number) {
      actions.stopAutoplay();
      _actions.play(index);
    },
    stopAutoplay() {
      const { autoplay } = state;
      autoplay && _actions.stopAutoplay();
    },
    startAutoplay() {
      _actions.startAutoplay();
    },
    destroy() {
      _actions.stopAutoplay();
      _actions.stop();
    },
  };

  autoplay && actions.startAutoplay();

  return {
    state,
    actions,
  };
}
