import { FunctionComponent, useEffect, useState } from 'react';
import Error404 from 'pages/Error404/Error404';
import { PageLoading } from 'components/shared/PageLoading';
import { IPageServices } from 'types/generated/strapi';
import MainMenu from 'components/shared/MainMenu';
import Footer from 'components/shared/Footer';
import SeoHead from 'components/shared/SeoHead';
import IconsAndText from 'components/sections/IconsAndText';
import TextBlock from 'components/sections/TextBlock';
import Hero from 'components/sections/Hero';
import * as strapi from '@casbah/strapi-fetch';
import { getStrapiURL } from '../../utils/urls';
import TitleCenter from 'components/sections/TitleCenter';
import Buttons from 'components/sections/Buttons';
import Ticker from 'components/sections/Ticker';

const ServicesPage: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageData, setPageData] = useState<IPageServices>();

  useEffect(() => {
    (async () => {
      const resp = await strapi.get<IPageServices>(
        getStrapiURL('/page-services')
      );
      if (resp.ok) setPageData(resp.payload);
      else console.error(resp.error);

      setPageIsLoading(false);
      document.dispatchEvent(new Event('page-fetched'));
    })();
  }, []);

  const { hero, textBlock, iconsAndText, buttons, ticker, titleCenter, seo } = {
    ...pageData,
  };

  if (!pageIsLoading && !pageData) return <Error404 />;

  return (
    <>
      <MainMenu />
      {pageIsLoading ? (
        <PageLoading />
      ) : (
        <>
          {seo && <SeoHead seo={seo} />}
          {hero && <Hero data={hero} />}
          {textBlock && <TextBlock data={textBlock} />}
          {titleCenter && <TitleCenter data={titleCenter} />}
          {iconsAndText && <IconsAndText data={iconsAndText} />}
          {buttons && <Buttons data={buttons} />}
          {ticker && <Ticker data={ticker} />}
        </>
      )}
      <Footer />
    </>
  );
};

export default ServicesPage;
