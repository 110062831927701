import React, { CSSProperties, useEffect } from 'react';
import {
  ICreatorsAnimation,
  ICreatorsAnimationItem,
} from 'types/generated/strapi';
import styles from './index.module.css';
import ScrollMagic, { SceneProgressEvent } from 'scrollmagic';
import anime from 'animejs';
import clsx from 'clsx';

interface CreatorsAnimationProps {
  data: ICreatorsAnimation;
}
const CreatorsAnimation = (props: CreatorsAnimationProps) => {
  const { items } = props.data;

  useEffect(() => {
    const controller = new ScrollMagic.Controller();

    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const sceneDuration = windowHeight * 1.5;

    var animeScene = anime
      .timeline({
        autoplay: false,
      })
      .add({
        targets: `.${styles.creatorsAnimationItem}:nth-child(2)`,
        easing: 'easeInQuad',
        top: ['100%', 0],
        duration: sceneDuration / 2,
      })
      .add({
        targets: `.${styles.creatorsAnimationItem}:nth-child(3)`,
        easing: 'easeInQuad',
        top: ['100%', 0],
        duration: sceneDuration / 2,
      });

    const scene = new ScrollMagic.Scene({
      triggerElement: '#creatorsAnimation',
      triggerHook: 'onLeave',
      duration: sceneDuration,
    })
      .on<SceneProgressEvent<'progress'>>('progress', (scroll) => {
        const { progress } = scroll;
        //@ts-ignore
        animeScene.seek(sceneDuration * progress);
      })
      .setPin('#creatorsAnimation')
      .setClassToggle('#creatorsAnimation', styles.creatorsAnimationActive)
      .addTo(controller);

    return () => {
      controller.removeScene(scene);
    };
  }, []);

  return (
    <div>
      <div id="creatorsAnimation" className={styles.creatorsAnimation}>
        {items.map((item, index) => (
          <CreatorsAnimationItem key={item.id} data={item} />
        ))}
      </div>
    </div>
  );
};

const CreatorsAnimationItem = ({ data }: { data: ICreatorsAnimationItem }) => {
  const {
    line1,
    line2,
    line3,
    line1color,
    line2color,
    line3color,
    backgroundColor,
    iconColor,
  } = data;

  return (
    <div
      className={clsx(styles.creatorsAnimationItem)}
      style={
        {
          '--background-color': backgroundColor,
          '--color1': line1color,
          '--color2': line2color,
          '--color3': line3color,
          '--icon-color': iconColor,
        } as CSSProperties
      }
    >
      <div className={styles.text}>
        {line1 && <div className={styles.line1}>{line1}</div>}
        {line2 && <div className={styles.line2}>{line2}</div>}
        {line3 && <div className={styles.line3}>{line3}</div>}
      </div>
    </div>
  );
};

export default CreatorsAnimation;
