import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { ITextBlock } from 'types/generated/strapi';
import styles from './index.module.css';

interface TextBlockProps {
  data: ITextBlock;
}
const TextBlock: FunctionComponent<TextBlockProps> = (props) => {
  const { line1, line2, line3, line4 } = props.data;
  const location = useLocation();
  const isServicesPage = location.pathname.includes('/services');
  const isBrandsPage = location.pathname.includes('/brands');

  return (
    <div
      className={clsx(
        styles.textBlock,
        isServicesPage && styles.textBlockServicesPage,
        isBrandsPage && styles.textBlockBrandsPage
      )}
    >
      <div className={styles.textBlockInner}>
        {line1 && <div className={styles.line1}>{line1}</div>}
        {line2 && <div className={styles.line2}>{line2}</div>}
        {line3 && (
          <div className={clsx(styles.line3, line4 && styles.line3ExtraSpace)}>
            <div className={styles.line3Text}>{line3}</div>
            {line4 && (
              <div className={styles.line4}>
                <div className={styles.line4Text}>{line4}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextBlock;
