import { fileIsVideo } from '@casbah/strapi-helpers';
import clsx from 'clsx';
import StrapiImage from 'components/shared/StrapiImage';
import { FunctionComponent, useMemo } from 'react';
import { IHero } from 'types/generated/strapi';
import replaceCommunitySeenFont from 'utils/replaceCommunitySeenFont';
import styles from './index.module.css';

interface HeroProps {
  data: IHero;
}

const Hero: FunctionComponent<HeroProps> = (props) => {
  const { backgroundMedia, backgroundMediaMobile, title, subtitle } =
    props.data;

  const backgroundMediaIsVideo = useMemo(
    () => fileIsVideo(backgroundMedia),
    [backgroundMedia]
  );

  const backgroundMediaMobileIsVideo = useMemo(
    () => fileIsVideo(backgroundMediaMobile),
    [backgroundMediaMobile]
  );

  if (!backgroundMedia) return null;

  return (
    <div
      className={clsx(
        styles.backgroundMediaWrapper,
        backgroundMediaMobile && styles.backgroundMediaWrapperWithMobile
      )}
    >
      {backgroundMediaIsVideo ? (
        <video
          src={backgroundMedia.url}
          className={styles.desktop}
          muted
          loop
          autoPlay
          playsInline
        />
      ) : (
        <StrapiImage
          image={backgroundMedia}
          className={styles.desktop}
          format="xlarge"
        />
      )}
      {backgroundMediaMobile && backgroundMediaMobileIsVideo && (
        <video
          src={backgroundMediaMobile.url}
          muted
          loop
          autoPlay
          playsInline
          className={styles.mobile}
        />
      )}
      {backgroundMediaMobile && !backgroundMediaMobileIsVideo && (
        <StrapiImage
          className={styles.mobile}
          image={backgroundMediaMobile}
          format="large"
        />
      )}
      <div className={styles.content}>
        {title && <div className={styles.title}>{title}</div>}
        {subtitle && (
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: replaceCommunitySeenFont(subtitle),
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Hero;
