import { FunctionComponent } from 'react';
import { IVideoGridAndText } from 'types/generated/strapi';
import { fileIsVideo } from '@casbah/strapi-helpers';
import StrapiImage from 'components/shared/StrapiImage';
import styles from './index.module.css';
import ReactMarkdown from 'react-markdown';
import StrapiLink from 'components/shared/StrapiLink';

interface VideoGridAndTextProps {
  data: IVideoGridAndText;
}

const VideoGridAndText: FunctionComponent<VideoGridAndTextProps> = (props) => {
  const { title, text, media, cta } = props.data;

  return (
    <div className={styles.videoGridAndText}>
      <div className={styles.videoGridAndTextInner}>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <ReactMarkdown
            children={text}
            className={styles.text}
            components={{
              strong: ({ node, ...props }) => (
                <span className={styles.textTitle} {...props} />
              ),
            }}
          />
          {cta && (
            <div className={styles.ctaWrapper}>
              <StrapiLink className={styles.cta} link={cta} />
            </div>
          )}
        </div>
        <div className={styles.media}>
          {media.map((mediaItem) => (
            <div key={mediaItem.id} className={styles.mediaItem}>
              {fileIsVideo(mediaItem) ? (
                <video src={mediaItem.url} muted loop autoPlay playsInline />
              ) : (
                <StrapiImage image={mediaItem} format="small" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoGridAndText;
