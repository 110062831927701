import StrapiLink from 'components/shared/StrapiLink';
import { FunctionComponent } from 'react';
import { IButtons } from 'types/generated/strapi';
import styles from './index.module.css';

interface ButtonsProps {
  data: IButtons;
}

const Buttons: FunctionComponent<ButtonsProps> = (props) => {
  const { primaryButton, secondaryButton } = props.data;

  return (
    <div className={styles.buttons}>
      <div className={styles.buttonsInner}>
        <div className={styles.buttonsWrapper}>
          <StrapiLink className={styles.primaryButton} link={primaryButton} />
          {secondaryButton && (
            <StrapiLink
              className={styles.secondaryButton}
              link={secondaryButton}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Buttons;
