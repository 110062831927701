import { FunctionComponent, useEffect, useState } from 'react';
import styles from './index.module.css';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { ReactComponent as MenuIcon } from 'assets/svg/menu.svg';
import clsx from 'clsx';
import { NavLink, Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import Email from 'assets/img/Email.png';
import Tiktok from 'assets/img/Tiktok.png';
import Instagram from 'assets/img/Instagram.png';

interface MainMenuProps {}

const MainMenu: FunctionComponent<MainMenuProps> = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const mainMenu = useAppSelector((state) => state.layout.mainMenu);

  const { links = [], socialLinks = [] } = { ...mainMenu };
  const showMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuOpen(true);
  };

  const hideMenu = () => setMenuOpen(false);

  useEffect(() => {
    const addEventListeners = () => {
      document.addEventListener('click', hideMenu);
    };

    const removeEventListeners = () => {
      document.removeEventListener('click', hideMenu);
    };

    addEventListeners();
    return () => removeEventListeners();
  }, []);

  const isRelativeLink = (url: string) => url.startsWith('/');

  return (
    <div className={clsx(styles.mainMenu, menuOpen && styles.mainMenuFixed)}>
      <div className={styles.mainMenuInner}>
        {/* Logo and menu buttons */}
        <div className={styles.logoContainer}>
          {/* Logo */}
          <Link className={styles.logo} onClick={hideMenu} to="/">
            Community X SEEN
          </Link>

          {/* Menu Icon */}
          <button
            className={clsx(
              styles.menuButton,
              menuOpen && styles.menuButtonHide
            )}
            onClick={showMenu}
          >
            <span className="visually-hidden">Show menu</span>
            <MenuIcon className={styles.menuIcon} />
          </button>

          {/* Close Icon */}
          <button
            className={clsx(
              styles.closeButton,
              !menuOpen && styles.closeButtonHide
            )}
            onClick={hideMenu}
          >
            <span className="visually-hidden">Hide menu</span>
            <CloseIcon className={styles.closeIcon} />
          </button>
        </div>

        {/* Links container */}
        <div
          className={clsx(
            styles.navLinksWrapper,
            menuOpen && styles.navLinksWrapperShow
          )}
          onClick={(event) => event.stopPropagation()}
        >
          <div className={styles.navItemsWrapper}>
            <ul className={styles.navItems}>
              {/* Links */}
              {links.map((link) => (
                <li className={styles.navbarLinkItem} key={link.id}>
                  {isRelativeLink(link.url) && (
                    <NavLink
                      className={({ isActive }) =>
                        clsx(
                          styles.navbarLinkItemLink,
                          isActive && styles.navbarLinkItemLinkActive,
                          link.label === 'Sign Up' &&
                            styles.navbarLinkItemLinkSignUp
                        )
                      }
                      onClick={hideMenu}
                      to={link.url}
                    >
                      {link.label}
                    </NavLink>
                  )}
                  {!isRelativeLink(link.url) && (
                    <a
                      className={clsx(
                        styles.navbarLinkItemLink,
                        link.label === 'Sign Up' &&
                          styles.navbarLinkItemLinkSignUp
                      )}
                      href={link.url}
                      rel="noreferrer"
                    >
                      {link.label}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.socialLinksWrapper}>
            <ul className={styles.socialLinks}>
              {/* Social links */}
              {socialLinks.map((socialLink, socialLinkIndex) => {
                const { url, icon } = socialLink;
                const image =
                  icon === 'Instagram'
                    ? Instagram
                    : icon === 'Tiktok'
                    ? Tiktok
                    : icon === 'Mail'
                    ? Email
                    : '';
                return (
                  <li
                    className={styles.socialLink}
                    key={`social-${socialLinkIndex}`}
                  >
                    <a href={url} target="_blank" rel="noreferrer">
                      <img
                        src={image}
                        alt="social media"
                        className={styles.socialIcon}
                      />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;
