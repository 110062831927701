import { FunctionComponent, useEffect, useState } from 'react';
import Error404 from 'pages/Error404/Error404';
import { PageLoading } from 'components/shared/PageLoading';
import { ICaseStudy } from 'types/generated/strapi';
import MainMenu from 'components/shared/MainMenu';
import Footer from 'components/shared/Footer';
import { useParams } from 'react-router-dom';
import styles from './index.module.css';
import ReactMarkdown from 'react-markdown';
import SeoHead from 'components/shared/SeoHead';
import * as strapi from '@casbah/strapi-fetch';
import { getStrapiURL, getStrapiMediaUrl } from 'utils/urls';

const CaseStudyPage: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageData, setPageData] = useState<ICaseStudy>();
  const { slug } = useParams();

  useEffect(() => {
    (async () => {
      if (!slug) return;
      const resp = await strapi.get<ICaseStudy[]>(
        getStrapiURL(`/case-studies?slug=${slug}`)
      );

      if (resp.ok) setPageData(resp.payload?.[0]);
      else console.error(resp.error);

      setPageIsLoading(false);
      document.dispatchEvent(new Event('page-fetched'));
    })();
  }, [slug]);

  const {
    name = '',
    overview,
    content = '',
    stats = [],
    seo,
    media,
  } = {
    ...pageData,
  };

  const mediaUrl = media?.url;
  const mediaIsVideo = !!media?.mime.startsWith('video/');

  if (!pageIsLoading && !pageData) return <Error404 />;

  const defaultSeo = {
    id: 1000,
    metaTitle: name + ' | Community X Seen',
  };

  return (
    <>
      <MainMenu />
      {pageIsLoading ? (
        <PageLoading />
      ) : (
        <>
          {<SeoHead seo={seo || defaultSeo} />}
          <div className={styles.caseStudy}>
            <div className={styles.caseStudyInner}>
              <div className={styles.columns}>
                <div className={styles.columnContent}>
                  <div className={styles.columnContentInner}>
                    <div className={styles.title}>{name}</div>
                    {overview && (
                      <div className={styles.subtitle}>{overview}</div>
                    )}

                    <div className={styles.contentColumns}>
                      <div className={styles.contentInnerColumns}>
                        <div className={styles.columnText}>
                          <ReactMarkdown
                            className={styles.text}
                            children={content}
                            components={{
                              h2: ({ node, ...props }) => (
                                <h2 className={styles.textH2} {...props}>
                                  {props.children}
                                </h2>
                              ),
                              h3: ({ node, ...props }) => (
                                <h3 className={styles.textH3} {...props}>
                                  {props.children}
                                </h3>
                              ),
                              h4: ({ node, ...props }) => (
                                <h4 className={styles.textH4} {...props}>
                                  {props.children}
                                </h4>
                              ),
                              em: ({ node, ...props }) => (
                                <span
                                  className={styles.textHighlight}
                                  {...props}
                                />
                              ),
                            }}
                          />
                        </div>
                        {!!stats.length && (
                          <div className={styles.columnStats}>
                            <div className={styles.stats}>
                              {stats.map((stat) => {
                                const { title, description, id } = stat;
                                return (
                                  <div key={id} className={styles.stat}>
                                    <span className={styles.statTitle}>
                                      {title + ' '}
                                    </span>
                                    <span className={styles.statDescription}>
                                      {description}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {mediaUrl && (
                  <div className={styles.columnMedia}>
                    <div className={styles.mediaWrapper}>
                      {mediaIsVideo ? (
                        <video
                          loop
                          muted
                          autoPlay
                          src={getStrapiMediaUrl(mediaUrl)}
                          playsInline
                        />
                      ) : (
                        <img src={getStrapiMediaUrl(mediaUrl)} alt="" />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default CaseStudyPage;
