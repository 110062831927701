import StrapiImage from 'components/shared/StrapiImage';
import { fileIsVideo } from '@casbah/strapi-helpers';
import { FunctionComponent, useMemo } from 'react';
import { IMediaRightTextCtaLeft } from 'types/generated/strapi';
import styles from './index.module.css';
import clsx from 'clsx';
import StrapiLink from 'components/shared/StrapiLink';

interface MediaRightTextCtaLeftProps {
  data: IMediaRightTextCtaLeft;
}

const MediaRightTextCtaLeft: FunctionComponent<MediaRightTextCtaLeftProps> = (
  props
) => {
  const {
    media,
    mediaOverflow,
    reverse,
    title,
    text,
    ctaPrimary,
    ctaSecondary,
  } = props.data;

  const backgroundMediaIsVideo = useMemo(() => fileIsVideo(media), [media]);

  return (
    <div className={clsx(styles.mediaRightTextCtaLeft)}>
      <div
        className={clsx(
          styles.mediaRightTextCtaLeftInner,
          reverse && styles.mediaRightTextCtaLeftInnerReverse
        )}
      >
        <div className={styles.content}>
          <div className={styles.contentInner}>
            <div className={styles.title}>{title}</div>
            <div className={styles.text}>{text}</div>
            {(ctaPrimary || ctaSecondary) && (
              <div className={styles.buttons}>
                {ctaPrimary && (
                  <StrapiLink className={styles.ctaPrimary} link={ctaPrimary} />
                )}
                {ctaSecondary && (
                  <StrapiLink
                    className={styles.ctaSecondary}
                    link={ctaSecondary}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className={clsx(styles.media, mediaOverflow && styles.mediaOverflow)}
        >
          {backgroundMediaIsVideo ? (
            <video src={media.url} muted loop autoPlay playsInline />
          ) : (
            <StrapiImage image={media} format="medium" />
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaRightTextCtaLeft;
