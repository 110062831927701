import { useState, useEffect, useRef } from 'react';

export const useSnapConroller = (check: 'center' | 'left') => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [activeCarouselItemIndex, setActiveCarouselItemIndex] = useState(0);

  // Set active carousel element on scroll event
  useEffect(() => {
    if (!carouselRef.current) return;

    const { current } = carouselRef;

    function setActiveCarouselItem() {
      Array.from(current.children).forEach((ele, index) => {
        switch (check) {
          case 'center':
            if (checkCenterAlign(ele)) {
              setActiveCarouselItemIndex(index);
            }
            break;
          case 'left':
            if (checkLeftAlign(ele)) {
              setActiveCarouselItemIndex(index);
            }
        }
      });
    }

    let startX: number;
    let scrollLeft: number;
    let isDown: boolean;

    function mouseIsDown(e: MouseEvent) {
      isDown = true;
      startX = e.pageX - current.offsetLeft;
      scrollLeft = current.scrollLeft;

      current.style.cursor = 'grabbing';
      current.style.userSelect = 'none';
    }

    function mouseUp() {
      isDown = false;
      current.style.cursor = 'grab';
      current.style.removeProperty('user-select');
    }

    function mouseLeave() {
      isDown = false;
      current.style.cursor = 'grab';
      current.style.removeProperty('user-select');
    }

    function mouseMove(e: MouseEvent) {
      if (isDown) {
        e.preventDefault();
        const x = e.pageX - current.offsetLeft;
        const walkX = (x - startX) * 5;
        current.scrollLeft = scrollLeft - walkX;
      }
    }

    current.addEventListener('mousedown', mouseIsDown);
    current.addEventListener('mouseup', mouseUp);
    current.addEventListener('mouseleave', mouseLeave);
    current.addEventListener('mousemove', mouseMove);
    current.addEventListener('scrollend', setActiveCarouselItem);

    return () => {
      current.removeEventListener('scrollend', setActiveCarouselItem);
      current.removeEventListener('mousedown', mouseIsDown);
      current.removeEventListener('mouseup', mouseUp);
      current.removeEventListener('mouseleave', mouseLeave);
      current.removeEventListener('mousemove', mouseMove);
    };
  }, [check]);

  return [carouselRef, activeCarouselItemIndex] as const;
};

const checkCenterAlign = (ele: Element) => {
  return (
    Math.abs(
      ele.getBoundingClientRect().left -
        (window.innerWidth - ele.getBoundingClientRect().width) / 2
    ) < 10
  );
};

const checkLeftAlign = (ele: Element) => {
  return (
    ele.getBoundingClientRect().left < 10 &&
    ele.getBoundingClientRect().left > -10
  );
};
