import { FunctionComponent } from 'react';
import { ITripleVideoAndText } from 'types/generated/strapi';

import styles from './index.module.css';

interface TripleVideoAndTextProps {
  data: ITripleVideoAndText;
}

const TripleVideoAndText: FunctionComponent<TripleVideoAndTextProps> = (
  props
) => {
  const { title, copy, videos } = props.data;

  return (
    <div className={styles.tripleVideoAndText}>
      <div className={styles.tripleVideoAndTextInner}>
        <div className={styles.content}>
          <div className={styles.titles}>
            <div className={styles.title}>{title}</div>
            <div className={styles.title}>{title}</div>
            <div className={styles.title}>{title}</div>
          </div>
          <div className={styles.copy}>{copy}</div>
        </div>
        <div className={styles.videos}>
          {videos.map((video) => (
            <video
              key={video.id}
              src={video.url}
              className={styles.video}
              playsInline
              autoPlay
              muted
              loop
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TripleVideoAndText;
