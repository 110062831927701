import { FunctionComponent, useEffect, useState } from 'react';
import Error404 from 'pages/Error404/Error404';
import { PageLoading } from 'components/shared/PageLoading';
import { ICaseStudy, IPageCaseStudies } from 'types/generated/strapi';
import MainMenu from 'components/shared/MainMenu';
import Footer from 'components/shared/Footer';
import SeoHead from 'components/shared/SeoHead';
import Hero from 'components/sections/Hero';
import * as strapi from '@casbah/strapi-fetch';
import { getStrapiURL } from '../../utils/urls';
import CaseStudiesGrid from 'components/sections/CaseStudiesGrid';

const CaseStudiesPage: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageData, setPageData] = useState<IPageCaseStudies>();
  const [caseStudies, setCaseStudies] = useState<ICaseStudy[]>();

  useEffect(() => {
    (async () => {
      const [respPage, respCaseStudies] = await Promise.all([
        strapi.get<IPageCaseStudies>(getStrapiURL('/page-case-studies')),
        strapi.get<ICaseStudy[]>(getStrapiURL('/case-studies?_limit=-1')),
      ]);
      if (respPage.ok) setPageData(respPage.payload);
      else console.error(respPage.error);

      if (respCaseStudies.ok)
        setCaseStudies(respCaseStudies.payload?.reverse());
      else console.error(respCaseStudies.error);

      setPageIsLoading(false);
      document.dispatchEvent(new Event('page-fetched'));
    })();
  }, []);

  const { hero, caseStudiesGrid, seo } = {
    ...pageData,
  };

  if (!pageIsLoading && !pageData) return <Error404 />;

  return (
    <>
      <MainMenu />
      {pageIsLoading ? (
        <PageLoading />
      ) : (
        <>
          {seo && <SeoHead seo={seo} />}
          {hero && <Hero data={hero} />}
          {caseStudiesGrid && (
            <CaseStudiesGrid
              data={caseStudiesGrid}
              caseStudies={caseStudies || []}
            />
          )}
        </>
      )}
      <Footer />
    </>
  );
};

export default CaseStudiesPage;
