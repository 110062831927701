import { FunctionComponent } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/svg/signup-arrow.svg';
import { ReactComponent as HeartIcon } from 'assets/svg/heart.svg';
import { ICtaSection } from 'types/generated/strapi';
import styles from './index.module.css';
import { Link } from 'react-router-dom';

interface CtaSectionProps {
  data: ICtaSection;
}

const CTASection: FunctionComponent<CtaSectionProps> = (props) => {
  const { ctaLabel, url } = props.data;
  return (
    <Link to={String(url)}>
      <div className={styles.ctaSection}>
        <div className={styles.cta}>
          <div className={styles.ctaWrapper}>
            <div className={styles.marker}><HeartIcon/></div>
            <h1 className={styles.ctaLabel}>{ctaLabel}</h1>
            <div className={styles.marker}><HeartIcon/></div>
          </div>
          <div className={styles.ctaArrow}><ArrowIcon/></div>
        </div>
      </div>
    </Link>
  );
};

export default CTASection;
