import StrapiImage from 'components/shared/StrapiImage';
import { fileIsVideo } from '@casbah/strapi-helpers';
import { FunctionComponent, useMemo } from 'react';
import { IMediaLeftTextRight } from 'types/generated/strapi';
import styles from './index.module.css';
import clsx from 'clsx';

interface MediaLeftTextRightProps {
  data: IMediaLeftTextRight;
}

const MediaLeftTextRight: FunctionComponent<MediaLeftTextRightProps> = (
  props
) => {
  const { titleSecondary, titlePrimary, media, reverse, theme, text } =
    props.data;

  const backgroundMediaIsVideo = useMemo(() => fileIsVideo(media), [media]);

  return (
    <div
      className={clsx(
        styles.mediaLeftTextRight,
        theme === 'dark' && styles.mediaLeftTextRightDarkTheme
      )}
    >
      <div
        className={clsx(
          styles.mediaLeftTextRightInner,
          reverse && styles.mediaLeftTextRightInnerReverse
        )}
      >
        <div className={styles.media}>
          {backgroundMediaIsVideo ? (
            <video src={media.url} muted loop autoPlay playsInline />
          ) : (
            <StrapiImage image={media} format="medium" />
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{titlePrimary}</div>
          {titleSecondary && (
            <div className={styles.titleSecondary}>{titleSecondary}</div>
          )}
          <div className={styles.text}>{text}</div>
        </div>
      </div>
    </div>
  );
};

export default MediaLeftTextRight;
