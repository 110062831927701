import clsx from 'clsx';
import { CSSProperties, FunctionComponent, useState } from 'react';
import { IQuote, IQuotes } from 'types/generated/strapi';
import styles from './index.module.css';
import { ReactComponent as QuoteIcon } from 'assets/svg/quote.svg';
import ReactMarkdown from 'react-markdown';

interface QuotesProps {
  data: IQuotes;
}
const Quotes: FunctionComponent<QuotesProps> = (props) => {
  const { copy, quotes, copyBackgroundColor, reverse } = props.data;

  return (
    <div className={styles.quotes}>
      <div className={styles.quotesInner}>
        <div
          className={clsx(
            styles.quotesBlock,
            reverse && styles.quotesBlockFlipped
          )}
        >
          <div
            className={styles.quotesCopy}
            style={
              { '--background-color': copyBackgroundColor } as CSSProperties
            }
          >
            <ReactMarkdown
              className={styles.quotesCopyText}
              children={copy}
              components={{
                strong: ({ node, ...props }) => (
                  <span className={styles.textTitle} {...props} />
                ),
              }}
            />
          </div>
          <QuotesAnimated quotes={quotes} />
        </div>
      </div>
    </div>
  );
};

const QuotesAnimated = ({ quotes }: { quotes: IQuote[] }) => {
  const [activeQuote, setActiveQuote] = useState(quotes[0]);

  const pickRandomQuote = () => {
    const pickRandom = (items: any[]) =>
      items[Math.floor(Math.random() * items.length)];

    let newQuote = pickRandom(quotes);
    while (newQuote.id === activeQuote.id) {
      newQuote = pickRandom(quotes);
    }

    setActiveQuote(newQuote);
  };

  return (
    <div className={styles.quotesAnimated}>
      <Quote quote={activeQuote} onDisappear={pickRandomQuote} />
    </div>
  );
};

const Quote = ({
  quote,
  onDisappear,
}: {
  quote: IQuote;
  onDisappear: () => void;
}) => {
  // const [fadeOut, setFadeOut] = useState(false);
  const { text, author } = quote;

  // const onAnimationEnd = (e: any) => {
  //   if (e.animationName === styles.fadeIn) {
  //     setTimeout(() => {
  //       setFadeOut(true);
  //     }, 3000);

  //     return;
  //   }
  //   if (e.animationName === styles.fadeOut) {
  //     setFadeOut(false);
  //     onDisappear();
  //   }
  // };

  return (
    <div
      // className={clsx(styles.quote, fadeOut && styles.fadeOut)}
      // onAnimationEnd={onAnimationEnd}
      className={clsx(styles.quote)}
    >
      <QuoteIcon className={styles.quoteIcon} />
      <div className={styles.quoteText}>{text}</div>
      <div className={styles.quoteAuthor}>{author}</div>
    </div>
  );
};

export default Quotes;
