import { FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ILink } from 'types/generated/strapi';

interface IStrapiLinkProps {
  link: ILink;
  className?: string;
  onClick?: (event: any) => void;
}

const StrapiLink: FunctionComponent<IStrapiLinkProps> = (props) => {
  const { link, className, onClick } = props;
  const { url, label } = link;

  const isRelativeLink = url.charAt(0) === '/';

  const linkLabel = useMemo(() => {
    return label || url || '';
  }, [label, url]);

  if (!url) return null;

  // External link
  if (!isRelativeLink) {
    return (
      <a
        className={className}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
      >
        {linkLabel}
      </a>
    );
  }
  // Internal link
  return (
    <Link onClick={onClick} className={className} to={url}>
      {linkLabel}
    </Link>
  );
};

export default StrapiLink;
