import { FunctionComponent } from 'react';
import { ICaseStudiesCarousel } from 'types/generated/strapi';
import styles from './index.module.css';
import { useSnapConroller } from 'utils/useSnapConroller';
import CaseStudiesItem from 'components/shared/CaseStudyItem';

const CaseStudiesCarousel: FunctionComponent<{ data: ICaseStudiesCarousel }> = (
  props
) => {
  const { title, subtitle, caseStudies } = props.data;
  const [carouselRef, activeCarouselItemIndex] = useSnapConroller('left');

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerInner}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
      </div>
      <div ref={carouselRef} className={styles.caseStudiesCarousel}>
        {caseStudies.map((caseStudy, i) => (
          <div key={caseStudy.id} className={styles.caseStudiesCarouselItem}>
            <CaseStudiesItem
              data={caseStudy}
              active={i === activeCarouselItemIndex}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CaseStudiesCarousel;
