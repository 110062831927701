import { FunctionComponent } from 'react';
import { ICaseStudiesGrid, ICaseStudy } from 'types/generated/strapi';
import styles from './index.module.css';
import StrapiImage from 'components/shared/StrapiImage';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';
import { splitByDash } from 'utils/splitByDash';

const CaseStudiesGrid: FunctionComponent<{
  data: ICaseStudiesGrid;
  caseStudies: ICaseStudy[];
}> = (props) => {
  const { title, subtitle } = props.data;
  const { caseStudies } = props;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        {title && (
          <div className={styles.header}>
            {title && <div className={styles.title}>{title}</div>}
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          </div>
        )}
        <div className={styles.caseStudiesGrid}>
          {caseStudies.map((caseStudy) => (
            <Link
              to={`/case-studies/${caseStudy.slug}`}
              key={caseStudy.id}
              className={styles.caseStudy}
            >
              <div className={styles.caseStudyImageWrapper}>
                <StrapiImage
                  className={styles.caseStudyImage}
                  image={caseStudy.thumbnail}
                  format="large"
                />
              </div>
              <div className={styles.caseStudyText}>
                <div className={styles.caseStudyBrand}>
                  {splitByDash(caseStudy.name)[0]}
                </div>
                <div className={styles.caseStudyName}>
                  {splitByDash(caseStudy.name)[1]}
                </div>
                <div className={styles.arrowIconWrapper}>
                  <ArrowIcon className={styles.arrowIcon} />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CaseStudiesGrid;
