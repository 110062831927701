import { FunctionComponent, useEffect, useState } from 'react';
import Error404 from 'pages/Error404/Error404';
import { PageLoading } from 'components/shared/PageLoading';
import { IPageFaq } from 'types/generated/strapi';
import MainMenu from 'components/shared/MainMenu';
import Footer from 'components/shared/Footer';
import Accordion from 'components/sections/Accordion';
import SeoHead from 'components/shared/SeoHead';
import Hero from 'components/sections/Hero';
import * as strapi from '@casbah/strapi-fetch';
import { getStrapiURL } from '../../utils/urls';

const FaqPage: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageData, setPageData] = useState<IPageFaq>();

  useEffect(() => {
    (async () => {
      const resp = await strapi.get<IPageFaq>(getStrapiURL('/page-faq'));
      if (resp.ok) setPageData(resp.payload);
      else console.error(resp.error);

      setPageIsLoading(false);
      document.dispatchEvent(new Event('page-fetched'));
    })();
  }, []);

  const { hero, accordion, seo } = {
    ...pageData,
  };

  if (!pageIsLoading && !pageData) return <Error404 />;

  return (
    <>
      <MainMenu />
      {pageIsLoading ? (
        <PageLoading />
      ) : (
        <>
          {seo && <SeoHead seo={seo} />}
          {hero && <Hero data={hero} />}
          {accordion && <Accordion data={accordion} />}
        </>
      )}
      <Footer />
    </>
  );
};

export default FaqPage;
